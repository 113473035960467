import Styles from "../styles/grid";

import MenuSVG from "../../../../assets/Menu.svg";

import { useContext, useEffect, useState } from "react";

import {
    IoMicOutline,
    IoMicOffOutline,
    IoVideocamOutline,
    IoVideocamOffOutline,
} from "react-icons/io5";
import { BiLogOut } from "react-icons/bi";

import { MdAdminPanelSettings } from "react-icons/md";
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useSelector } from "react-redux";
import { Tooltip } from 'antd';
import { BaseContext } from "../../../../app/BaseConext";
import { Button, Modal } from 'antd';
import KickParticipantModal from "../modules/kickParticipantModal";

function SidebarMemberRowComponent({ name, email, memberId, adminIds, admin, userId }) {
    const { SocketConnection } = useContext(BaseContext);
    const audioOn = useSelector(({ roomSlice }) => {
        return roomSlice.users[memberId]?.uiState?.audioOn;
    });
    const cameraOn = useSelector(({ roomSlice }) => {
        return roomSlice.users[memberId]?.uiState?.cameraOn;
    });
    const [mic, setMic] = useState(true);
    const [video, setVideo] = useState(true);
    const {
        SidebarHR,
        SidebarMemberRowMiddle,
        SidebarMemberRowLeft,
        SidebarMemberRowButton,
        SidebarMemberRowRight,
        SidebarMemberRow,
        SidebarMemberDesignation,
        SidebarMemberName,
        SidebarMemberRowLeftMiddle,
    } = Styles;


    const [openKickParticipantModal, setOpenKickParticipantModal] = useState(false);

    return (
        <>
            {
                openKickParticipantModal
                    ?
                    (<KickParticipantModal
                        openKickParticipantModal={openKickParticipantModal}
                        setOpenKickParticipantModal={setOpenKickParticipantModal}
                        SocketConnection={SocketConnection}
                        name={name}
                        memberId={memberId}
                    />)
                    :
                    <></>
            }
            <SidebarMemberRow>
                <SidebarMemberRowLeftMiddle>
                    <SidebarMemberRowLeft>
                        <Avatar size={35} icon={<UserOutlined />} />
                    </SidebarMemberRowLeft>
                    <SidebarMemberRowMiddle>
                        <SidebarMemberName style={{ fontWeight: "600" }}>
                            {name}
                        </SidebarMemberName>
                        <SidebarMemberDesignation >
                            {email}
                        </SidebarMemberDesignation>
                    </SidebarMemberRowMiddle>
                </SidebarMemberRowLeftMiddle>
                <SidebarMemberRowRight>

                    <SidebarMemberRowButton onClick={() => { }}>
                        {
                            admin ? <Tooltip title="Admin">
                                <MdAdminPanelSettings size="20px" color="white" />
                            </Tooltip> : ""
                        }
                    </SidebarMemberRowButton>
                    <SidebarMemberRowButton onClick={() => { }}>
                        {cameraOn ? <IoVideocamOutline size="20px" color="white" /> : <IoVideocamOffOutline size="20px" color="white" />}
                    </SidebarMemberRowButton>
                    <SidebarMemberRowButton onClick={() => { }}>
                        {audioOn ? <IoMicOutline size="20px" color="white" /> : <IoMicOffOutline size="20px" color="white" />}
                    </SidebarMemberRowButton>

                    {
                        !adminIds?.includes(memberId) && adminIds?.includes(userId) ?

                            <SidebarMemberRowButton style={{ cursor: "pointer" }} onClick={() => setOpenKickParticipantModal(true)}>
                                <Tooltip title="Remove">
                                    <BiLogOut size="20px" color="white" />
                                </Tooltip>
                            </SidebarMemberRowButton>
                            : <></>
                    }

                </SidebarMemberRowRight>
            </SidebarMemberRow>
            <SidebarHR style={{ margin: "0px 15px 0px 15px", }} />
        </>
    );
};
export default SidebarMemberRowComponent;
