export async function updateVideoTrack(state, { MyStreams, SocketConnection }) {
  return await MyStreams.getInstance().then(async ({ videoStream }) => {
    if (!videoStream) return { cameraOn: false };
    const videoTracks = videoStream
      .getTracks()
      .find((track) => track.kind === "video");
    if (!videoTracks) return { cameraOn: false };
    if (state.uiState.cameraOn) {
      await SocketConnection.UserCameraOff();
      videoTracks.stop();
      return { cameraOn: false };
    }

    const newStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: state.uiState.audioOn,
    });
    await SocketConnection.UserCameraOn(newStream);
    await videoStream.removeTrack(videoTracks);
    await videoStream.addTrack(newStream.getVideoTracks()[0]);
    return { cameraOn: true };
  });
}


export async function updateCamera({ MyStreams, SocketConnection, deviceId , newIndex }) {
  return await MyStreams.switchCameraNew(deviceId).then(async ({ videoStream }) => {

    // dispatch(roomSlice.actions.updateCurrentVideoDeviceIndex(newIndex))
    // dispatch(roomSlice.actions.updateCurrentVideoDevice(option.key))
    await SocketConnection.UserCameraOff();
    await SocketConnection.UserCameraOn(videoStream);
    await SocketConnection.updateCurrentStream(videoStream)

    await videoStream.addTrack(videoStream.getVideoTracks()[0]);
    return { cameraOn: true, newIndex };

  });
  // return await MyStreams.switchCamera(newIndex).then(async ({ videoStream, deviceId }) => {
  //   console.log(newIndex, "CURRENT DEVICE INDEX");
  //   console.log(deviceId, "DEVICE ID");

  //   await SocketConnection.UserCameraOff();
  //   await SocketConnection.UserCameraOn(videoStream);
  //   await SocketConnection.updateCurrentStream(videoStream)

  //   console.log("TESTING ", videoStream.getVideoTracks());
  //   await videoStream.addTrack(videoStream.getVideoTracks()[0]);
  //   return { cameraOn: true, newIndex };
  // });
}