
export const Logo = () => {
    return (
        <div style={{
            paddingInlineStart: "15px",
            paddingTop: "10px",
            "display": "flex",
            "top": "1%",
            "left": "1%",
            "gap": "10px",
            alignItems: "flex-end"
        }}>
            <img
                src="logo192.png"
                style={{
                    width: "40px",
                }}
                alt=""
            />
            <div style={{ fontSize: "18px", fontWeight: "500", marginBottom: "2px" }}>
                Workwise
            </div>
        </div>
    )
}