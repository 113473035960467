import { useState, useEffect, useContext } from "react";
import { Button, Modal, Menu, Select, message, Switch } from "antd";
import { MdAudiotrack, MdVideocam } from "react-icons/md";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { roomSlice } from "../../../call/store/slice";
import { BaseContext } from "../../../../app/BaseConext";
import BeepSound from "../../../../sounds/ding-126626.mp3"
import PitchDetector from "./PitchDetector";
import { FaGear } from "react-icons/fa6";
import Styles from "../styles/JoinCall.style";
import gridStyles from "../../../call/ui/styles/grid";
import { toggleCamera } from "../../../call/store/actions";
import { MdFlipCameraIos } from "react-icons/md";


const {
    FloatingButton
} = Styles;

const {
    SidebarInfoBodyButton,
} = gridStyles;

export const SettingsModal = (props) => {
    const { audioRef, audio, videoRef, inCall = false, camera } = props;
    const dispatch = useDispatch();
    const { MyStreams, SocketConnection } = useContext(BaseContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedKey, setSelectedKey] = useState("audio");
    const [audioInputDevices, setAudioInputDevices] = useState([]);
    const [audioOutputDevices, setAudioOutputDevices] = useState([]);
    const [videoOutputDevices, setVideoOutputDevices] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const currentInputDevice = useSelector(
        ({ roomSlice }) => roomSlice.uiState.currentInputDevice
    );
    const currentOutputDevice = useSelector(
        ({ roomSlice }) => roomSlice.uiState.currentOutputDevice
    );
    const currentVideoDevice = useSelector(
        ({ roomSlice }) => roomSlice.uiState.currentVideoDevice
    );
    const myId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );
    const currentVideoDeviceIndex = useSelector(
        ({ roomSlice: { uiState } }) => uiState.currentVideoDeviceIndex)

    useEffect(() => {
        if (currentInputDevice) {
            MyStreams.getInstance().then((instance) => {
                if (audioRef) {
                    audioRef.current.srcObject = instance.audioStream;
                }
                navigator.mediaDevices.getUserMedia({
                    audio: { deviceId: currentInputDevice !== 'default' ? { exact: currentInputDevice } : undefined }
                }).then((stream) => {
                    instance.audioStream = stream;  // Replace the instance stream
                    if (currentInputDevice !== "default") {
                        message.info("Audio input device changed successfully.");
                    }
                }).catch(error => {
                    console.error("Error changing microphone:", error);
                    message.info('Error setting audio output:' + error);
                });
            });
        }
    }, [currentInputDevice]);


    useEffect(() => {
        if (currentOutputDevice && audioRef?.current) {
            MyStreams.getInstance().then((instance) => {

                audioRef.current.srcObject = instance.audioStream; // Assign stream to audio element

                if (typeof audioRef.current.setSinkId === "function") {
                    audioRef.current.setSinkId(currentOutputDevice)
                        .then(() => {
                            // console.log(`Audio output set to ${currentOutputDevice}`);
                            if (currentOutputDevice !== "default") {
                                message.info("Audio output device changed successfully.");
                            }
                        })
                        .catch(error => {
                            console.error("Error setting audio output:", error);

                            if (error.name === "NotFoundError") {
                                message.error("Selected output device not found. Switching to default.");
                            } else if (error.name === "SecurityError") {
                                message.error("Browser restrictions prevent changing the output device.");
                            } else {
                                message.error("Error setting audio output: " + error.message);
                            }
                        });
                } else {
                    console.warn("setSinkId is not supported in this browser.");
                    message.warning("Your browser does not support changing the audio output device.");
                }
            });
        }

        return () => {
            if (audioRef?.current) {
                audioRef.current.srcObject = null; // Cleanup: Remove stream from audio element
            }
        };
    }, [currentOutputDevice, audioRef]);


    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        // Fetch available audio input devices
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            const audioInputs = devices
                .filter((device) => device.kind === "audioinput")
                .map((device) => {
                    // console.log("DEVICE : ", device);
                    return {
                        key: device.deviceId,
                        label: device.label || "Unknown Microphone",
                    }
                });
            const audioOutputs = devices
                .filter((device) => device.kind === "audiooutput")
                .map((device) => {
                    // console.log("DEVICE : ", device);
                    return {
                        key: device.deviceId,
                        label: device.label || "Unknown Speaker",
                    }
                });

            let videoOutputs = devices
                .filter((device) => device.kind === "videoinput")
                .map((device) => {
                    // console.log("DEVICE : ", device);
                    return {
                        key: device.deviceId,
                        label: device.label || "Unknown Camera",
                    }
                });

            // console.log("VIDEO DEVICES: ", videoOutputs);
            // videoOutputs = videoOutputs.slice(0, 2)
            setAudioInputDevices(audioInputs);
            setAudioOutputDevices(audioOutputs);
            setVideoOutputDevices(videoOutputs)
        });
    }, [audio, isModalOpen]);


    const showModal = () => setIsModalOpen(true);
    const handleCancel = () => setIsModalOpen(false);

    // console.log("CURRENT VIDEO DEVICE ID: ", currentVideoDevice);
    const handleCameraSwitch = async (option) => {
        try {
            // const newIndex = (currentVideoDeviceIndex === 0 && videoOutputDevices > 1) ? 1 : 0;
            if (currentVideoDevice !== option.key) {

                let newIndex = 0;
                const deviceLabel = option.label.toLowerCase()
                if (option.key == currentVideoDevice || deviceLabel.includes("front")) {
                    newIndex = 0;
                }
                else if (option.key !== currentVideoDevice || deviceLabel.includes("back")) {
                    newIndex = 1;
                }
                if (inCall) {
                    dispatch(toggleCamera({ MyStreams, SocketConnection, deviceId: option.key, newIndex })).then((result) => {
                        dispatch(roomSlice.actions.updateCurrentVideoDeviceIndex(newIndex))
                        dispatch(roomSlice.actions.updateCurrentVideoDevice(option.key))
                        // console.log("RESULT ", result);
                    });
                }
                else {
                    MyStreams.switchCameraNew(option.key).then((instance) => {
                        videoRef.current.srcObject = instance.videoStream;
                        audioRef.current.srcObject = instance.audioStream;
                        dispatch(roomSlice.actions.updateCurrentVideoDeviceIndex(newIndex))
                        dispatch(roomSlice.actions.updateCurrentVideoDevice(option.key))
                    });
                }

            }

        }
        catch (error) {
            console.error("Error Toggling Camera", error);
        }
    };

    const renderDropdown = (type, options) => {

        const getTypeKey = () => {
            if (type === "audioInput") {
                return currentInputDevice;
            }
            else if (type === "audioOutput") {
                return currentOutputDevice;
            }
            else if (type === "videoOutput") {
                return currentVideoDevice;
            }
        }
        const checkDisbaled = () => {
            if (type === "audioInput" && !audio) {
                return true;
            }
            if (type === "videoOutput" && !camera) {
                return true;
            }
            else if (type === "audioOutput" && !audioOutputDevices.length) {
                return true;
            }
            else if (type === "videoOutput" && !videoOutputDevices.length) {
                return true;
            }
            else {
                return false;
            }
        }
        const onClickSelectChange = (option) => {
            if (type == "audioInput") {
                // console.log("chnaging audio input current device is ", option.key);
                dispatch(roomSlice.actions.updateCurrentInputDevice(option?.key));
            }
            if (type == "audioOutput") {
                dispatch(roomSlice.actions.updateCurrentOutputDevice(option?.key));
            }
            if (type == "videoOutput") {
                console.log("CHANGING CAMERA TOGGLE");
                handleCameraSwitch(option);
            }
        }

        const getDefaultValues = () => {
            if (type == "audioInput") {
                return "default - Microphone"
            }
            if (type == "audioOutput") {
                return "default - Speaker"
            }
        }

        const getCurrentValue = () => {
            if (options?.length) {
                if (type == "audioInput") {
                    return audioInputDevices.find((device) => {
                        if (device.key === currentInputDevice) {
                            return device.label
                        }
                    })
                }
                if (type == "audioOutput") {
                    return audioOutputDevices.find((device) => {
                        if (device.key === currentOutputDevice) {
                            return device.label
                        }
                    })
                }
                if (type == "videoOutput") {
                    return videoOutputDevices.find((device) => {
                        if (device.key === currentVideoDevice) {
                            return device.label
                        }
                    })
                }
            }
            else {
                return getDefaultValues()
            }
        }

        return <Select
            disabled={checkDisbaled()}
            style={{ width: "100%" }}
            placeholder={`Select an option`}
            value={getCurrentValue()}
            dropdownRender={(menu) => (
                <div style={{ background: "white", padding: "5px 0" }}>
                    {options?.map((option) => (
                        <div
                            key={option?.key}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "8px 12px",
                                cursor: "pointer",
                                fontWeight: "normal",
                                color: "black",
                                background: getTypeKey() === option.key ?
                                    "#f0f0f0" :
                                    "transparent",
                            }}
                            onClick={() => onClickSelectChange(option)}
                        >
                            <span>{option?.label}</span>
                            <CheckCircleOutlined
                                style={{
                                    color: (getTypeKey() === option?.key) || (!getTypeKey() && 'default' === option?.key) || (options.length === 1) ?
                                        "green" :
                                        "gray",
                                    fontSize: "16px",
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}
        />
    };

    const renderAudioSettings = () => {

        const playTestSound = () => {
            const audio = new Audio(BeepSound);
            audio.play();
        };

        return <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <div>
                <div>
                    <h3>🎤 Microphone</h3>
                    {renderDropdown("audioInput", audioInputDevices)}
                    <PitchDetector />
                </div>
                <div style={{ marginTop: "20px" }}>
                    <h3>🔊 Audio Output </h3>
                    {renderDropdown("audioOutput", audioOutputDevices)}
                    <div style={{ marginTop: "10px" }}>
                        <h4>Test Audio Output</h4>
                        <Button onClick={playTestSound}>Play Test Sound</Button>
                    </div>
                </div>
            </div>
        </div>
    };
    const renderVideoSettings = () => {
        return <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <div>
                <div>
                    <h3>📷 Camera</h3>
                    <div style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}>
                        {renderDropdown("videoOutput", videoOutputDevices)}
                        <Button
                            disabled={!camera}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5px"
                            }}
                            onClick={
                                () => dispatch(roomSlice.actions.updateCurrentVideoDeviceIndex(!currentVideoDeviceIndex))
                            }
                        >
                            <MdFlipCameraIos />
                            {currentVideoDeviceIndex ? "Mirror" : "Unmirror"} Camera
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    };

    const menuItems = [
        { key: "audio", label: "Audio", icon: <MdAudiotrack size={20} /> },
        { key: "video", label: "Video", icon: <MdVideocam size={20} /> },
    ];

    const menuItemsUI = {
        "audio": renderAudioSettings(),
        "video": renderVideoSettings()
    }

    return (
        <>
            {
                inCall ?
                    <SidebarInfoBodyButton
                        style={{ marginBottom: "10px" }}
                        onClick={showModal}
                    >
                        <FaGear size={20} style={{ color: "white" }} />
                        Manage Settings
                    </SidebarInfoBodyButton> : <FloatingButton style={{ padding: "10px" }} onClick={showModal}>
                        <FaGear size={isMobile ? 12 : 18}/>
                    </FloatingButton>
            }
            <Modal
                title="Settings"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                width={isMobile ? "90%" : "700px"}
            >
                <div style={{ display: "flex", gap: "16px", minHeight: "300px" }}>
                    <Menu
                        style={{
                            width: isMobile ? "60px" : "200px",
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                        }}
                        mode="vertical"
                        selectedKeys={[selectedKey]}
                        onClick={(e) => setSelectedKey(e.key)}
                        items={menuItems.map(({ key, label, icon }) => ({
                            key,
                            label: isMobile ? (
                                <span style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                    {icon}
                                </span>
                            ) : (
                                <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    {icon} {label}
                                </span>
                            ),
                        }))}
                    />
                    <div style={{ flex: 1, padding: "10px" }}>
                        {menuItemsUI[selectedKey]}
                    </div>
                </div>
            </Modal>
        </>
    );
};
