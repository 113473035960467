import Styles from "../styles/grid";
import { useMediaQuery } from "react-responsive";
import React, { useState, useRef, useEffect } from "react";
import VideoStreamTagComponent from "./VideoStreamTagComponent";
import { useSelector, useDispatch } from "react-redux";
import { roomSlice } from "../../store/slice";
import { windowType } from "../../../../app/utils/enums";
import ScreenShareComponent from "./ScreenShareComponent";
import AudioStreamTagComponent from "./audioStreamTagComponent";
import Timer from "../modules/Timer";

const VideoStreamComponent = (props) => {

  const { openSidebar, openMobileSidebar, sidebar, onClickSidebarClose } = props;
  const { VideoStreamRow, VideoStreamCol, VideoStreamMain } = Styles;
  const dispatch = useDispatch();
  const userIds = useSelector((state) => state.roomSlice.userIds);

  const users = useSelector(({ roomSlice: { users } }) => users);

  const pinCount = useSelector((state) => state.roomSlice.uiState.pinCount);

  const [mainScreenUsers, setMainScreenUsers] = useState([]);
  const [colCount, setColCount] = useState(1);
  const viewType = useSelector(({ roomSlice }) => roomSlice.uiState.viewType);

  const isDesktop = useMediaQuery({ minWidth: 900 });
  const isTablet = useMediaQuery({ minWidth: 700, maxWidth: 900 });
  const isSmallTablet = useMediaQuery({ minWidth: 500, maxWidth: 700 });
  const ismobile = useMediaQuery({ maxWidth: 500 });

  isDesktop
    ? dispatch(roomSlice.actions.setView(windowType.Desktop))
    : isTablet
      ? dispatch(roomSlice.actions.setView(windowType.Tablet))
      : isSmallTablet
        ? dispatch(roomSlice.actions.setView(windowType.SmallTablet))
        : ismobile
          ? dispatch(roomSlice.actions.setView(windowType.Mobile))
          : dispatch(roomSlice.actions.setView(windowType.Desktop));


  // console.log(pinCount, viewType, "Video Parent Compoent");

  useEffect(() => {
    // console.log(pinCount, viewType, "Video Parent Compoent - useEffect");
    let splitScreen = isDesktop
      ? 8
      : isTablet
        ? 6
        : isSmallTablet
          ? 4
          : ismobile
            ? 4
            : 0;
    if (pinCount !== 0) {
      let pinnedUsers = [];
      for (let user in users) {
        if (users[user].uiState.pin) pinnedUsers.push(user);
      }
      // console.log(pinnedUsers);
      // console.log("PINCOUNT IF : ", pinCount);
      openSidebar("sidebarlayout")
      setMainScreenUsers(pinnedUsers);
      setColCount(pinnedUsers.length);
    } else {
      // console.log("PINCOUNT ELSE : ", pinCount);
      if (sidebar) {
        console.log("SIDE BAR IN ELSE: " , sidebar);
        onClickSidebarClose()
      }
      if (userIds.length > splitScreen) {
        let displayableUsers = userIds.slice(0, splitScreen);
        setMainScreenUsers(displayableUsers);
        setColCount(displayableUsers.length);
      } else {
        setMainScreenUsers(userIds);
        setColCount(userIds.length);
      }
    }
  }, [pinCount, viewType, userIds]);



  return (
    <VideoStreamMain>
      <Timer />
      <VideoStreamRow colCount={colCount} viewType={viewType}>
        {mainScreenUsers.map((userId, index) => {
          const screenShare = userId.endsWith("_screen_share");
          // console.log("USER: " + userId?.split("_")[0])
          if (screenShare) return (

            <VideoStreamCol className="videoBox" key={screenShare}>
              <ScreenShareComponent userId={userId} />
            </VideoStreamCol>
          )
          return (
            <VideoStreamCol className="videoBox" key={userId}>
              <VideoStreamTagComponent userId={userId} colCount={colCount} mainScreenUsers={mainScreenUsers} />
            </VideoStreamCol>
          );

        })}
        {
          userIds.map((userId) => {
            return (<AudioStreamTagComponent key={userId} userId={userId} />)
          })
        }
      </VideoStreamRow>
    </VideoStreamMain>
  );
};

export default VideoStreamComponent;
