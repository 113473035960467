import Styles from "../styles/grid";
import Styles2 from "../styles/Call.styles";
import EndCall from "../../../../assets/EndCall.svg";
import { useSelector, useDispatch } from "react-redux";
import { toggleVideoTracks, toggleAudioTracks } from "../../store/actions";
import {
  IoMicOutline,
  IoMicOffOutline,
  IoVideocamOutline,
  IoVideocamOffOutline,
  IoCameraReverseOutline
} from "react-icons/io5";

import { BaseContext } from "../../../../app/BaseConext";
import React, { useContext, useState } from "react";
import { roomSlice } from "../../store/slice";
import { FaLock, FaUnlock } from "react-icons/fa";
import { updateRoom } from "../../../../data/api/Api";
import { useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { WarningIcon } from "../../../join_call/ui/modules/WarningIcon";
import { getTooltipTitle } from "../../../../app/helper";


const { BottomMidDiv } = Styles;

function BottomMidComponent() {
  const { MyStreams, SocketConnection } = useContext(BaseContext);
  const { CallOptionButton, ButtonView2, Space } = Styles2;
  const [disableLock, setDisableLock] = useState(false);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const roomId = new URLSearchParams(search).get("room");
  const isLock = useSelector(
    ({ roomSlice: { uiState } }) => uiState.isLock
  );
  const camera = useSelector(
    ({ roomSlice: { users }, generalSlice }) =>
      users[generalSlice.userId].uiState.cameraOn
  );
  const audio = useSelector(
    ({ roomSlice: { users }, generalSlice }) =>
      users[generalSlice.userId].uiState.audioOn
  );
  const waitingCamera = useSelector(
    ({ roomSlice: { uiState } }) => uiState.waitingCamera
  );
  const handleMic = async () => {
    dispatch(toggleAudioTracks({ MyStreams, SocketConnection }));
  };

  const adminIds = useSelector(
    ({ generalSlice }) => generalSlice.adminIds
  );
  const userId = useSelector(
    ({ generalSlice }) => generalSlice.userId
  );
  const showEndMeetingModal = useSelector(
    ({ roomSlice: { uiState } }) => uiState.showEndMeetingModal
  );
  const accessToken = useSelector(
    ({ generalSlice }) => generalSlice.accessToken
  );

  const handleLeaveCall = async () => {
    dispatch(roomSlice.actions.updateModalView(!showEndMeetingModal));
  };
  const handleLockSwitch = async () => {
    setDisableLock(true);
    dispatch(roomSlice.actions.updateRoomLock({ isLock: !isLock }))
    const data = { isLock: !isLock }
    const response = await updateRoom(roomId, data, accessToken)
    if (!response.data.updated) {
      alert("Unexpected Error in Locking Room ");
      dispatch(roomSlice.actions.updateRoomLock({ isLock: !isLock }))
    }
    SocketConnection.NotifyRoomLockUpdate(data)
    setDisableLock(false);
  }
  const handleVideo = async () => {
    if (!waitingCamera) {
      dispatch(toggleVideoTracks({ MyStreams, SocketConnection }));
    }
  };
  const { mediaDeviceErrors } = useSelector(
    ({ generalSlice }) => generalSlice
  );


  const displayButton = (type) => {
    const isAudio = type === "audio";
    const permissionKey = `${type}PermissionGranted`;
    const deviceKey = `${type}Device`;
    const isEnabled = isAudio ? audio : camera;
    const toggleFunction = isAudio ? handleMic : handleVideo;
    const OnIcon = isAudio ? <IoMicOutline size="20px" color="white" /> : <IoVideocamOutline size="20px" color="white" />;
    const OffIcon = isAudio ? <IoMicOffOutline size="20px" color="white" /> : <IoVideocamOffOutline size="20px" color="white" />;

    return (
      <Tooltip title={getTooltipTitle(type, mediaDeviceErrors)}>
        <ButtonView2 onClick={toggleFunction} style={{ position: "relative", background: "#1A5669" }}>
          {!mediaDeviceErrors[permissionKey] && mediaDeviceErrors[deviceKey] && (
            <WarningIcon color="yellow" />
          )}
          {!mediaDeviceErrors[deviceKey] && <WarningIcon color="red" />}
          {isEnabled ? OnIcon : OffIcon}
        </ButtonView2>
      </Tooltip>
    );
  };

  return (
    <>
      <BottomMidDiv>
        {/* {
          videoDevices.length > 1 &&
          <>
            <CallOptionButton>
              <ButtonView2 onClick={handleCameraSwitch}
                disabled={!camera}
                style={!camera ? { backgroundColor: "gray", borderColor: "gray" } : { background: "#1A5669" }}>
                <IoCameraReverseOutline size="20px" color="white" />
              </ButtonView2>
            </CallOptionButton>
            <Space />
          </>
        } */}
        <CallOptionButton>
          {displayButton("video")}
        </CallOptionButton>
        <Space />
        <CallOptionButton>
          {displayButton("audio")}
        </CallOptionButton>
        <Space />
        <CallOptionButton>
          <ButtonView2 style={{ background: "red" }} onClick={handleLeaveCall}>
            <img src={EndCall} />
          </ButtonView2>
        </CallOptionButton>
        <Space />
        {
          adminIds?.includes(userId) &&
          <>
            <CallOptionButton>
              <ButtonView2 disabled={disableLock}
                style={disableLock ?
                  { background: "#1A5669", opacity: 0.5 }
                  : { background: "#1A5669", opacity: 1 }}
                onClick={handleLockSwitch}>
                {isLock ?
                  <FaLock size={15} /> :
                  <FaUnlock size={15} />}

              </ButtonView2>
            </CallOptionButton>
            <Space />
          </>
        }
        {/* <InputDeviceToggler inCall={true} audio={mic} /> */}
      </BottomMidDiv>
    </>
  );
}
export default BottomMidComponent;
