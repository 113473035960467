import React, { useContext, useEffect, useRef, useState } from "react";
import { BaseContext } from "../../../../app/BaseConext";


const PitchDetector = () => {
    const [activeBars, setActiveBars] = useState(0);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const [pitch, setPitch] = useState(0);
    const bufferLength = 2048;
    const sampleRate = 44100;
    const lastPitchTime = useRef(Date.now());

    const { MyStreams } = useContext(BaseContext);

    useEffect(() => {
        startPitchDetection();
        return () => stopPitchDetection();
    }, []);

    const stopPitchDetection = () => {
        MyStreams.getInstance().then((instance) => {
            const audioStream = instance.audioStream
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());

            }
        })
        if (audioContextRef.current) {
            audioContextRef.current.close();
        }
    };

    const startPitchDetection = async () => {
        try {
            let audioStream;
            MyStreams.getInstance().then((instance) => {
                audioStream = instance.audioStream
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

                const source = audioContextRef.current.createMediaStreamSource(audioStream);
                analyserRef.current = audioContextRef.current.createAnalyser();
                analyserRef.current.fftSize = bufferLength;

                source.connect(analyserRef.current);
                detectPitch();
            });
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    };

    const detectPitch = () => {
        const buffer = new Float32Array(bufferLength);
        const processPitch = () => {
            analyserRef.current.getFloatTimeDomainData(buffer);
            const pitchValue = autoCorrelate(buffer, sampleRate);

            if (pitchValue > 0) {
                lastPitchTime.current = Date.now(); // Reset timeout
            }


            // Normalize to 10 bars (0 - 300 Hz → 0 - 10 bars)
            const normalized = Math.min(10, Math.max(0, Math.round((pitchValue / 300) * 10)));
            // console.log();
            if (normalized) {
                // console.log("Normalized: ", normalized);
                setPitch(pitchValue);
                setActiveBars(normalized);
            }

            requestAnimationFrame(processPitch);
        };
        processPitch();
    };

    const autoCorrelate = (buffer, sampleRate) => {
        let size = buffer.length;
        let maxCorr = 0;
        let bestOffset = -1;

        for (let i = 0; i < size / 2; i++) {
            let sum = 0;
            for (let j = 0; j < size / 2; j++) {
                sum += buffer[j] * buffer[j + i];
            }
            if (sum > maxCorr) {
                maxCorr = sum;
                bestOffset = i;
            }
        }

        return bestOffset > 0 ? sampleRate / bestOffset : 0;
    };

    // If no pitch change for 500ms, start reducing bars
    useEffect(() => {
        const interval = setInterval(() => {
            if (Date.now() - lastPitchTime.current > 500 && activeBars > 0) {
                setActiveBars(prev => Math.max(0, prev - 1));
                setPitch(prev => Math.max(0, prev - (prev / 2)))
            }
        }, 15); // Decrease bars gradually

        return () => clearInterval(interval);
    }, [activeBars]);

    return (
        <div className="p-4 w-96 bg-gray-100 rounded-lg shadow text-center">
            <p style={{ marginTop: "5px" }}>Detected Pitch: <strong>{pitch.toFixed(2)} Hz</strong></p>
            <div style={{ marginTop: "0px" }}>
                <div
                    style={{
                        display: "flex",
                        gap: "2px",
                        background: "#ccc",
                        height: "5px",
                        borderRadius: "5px",
                        overflow: "hidden",
                        width: "100%",
                    }}
                >
                    {[...Array(10)].map((_, i) => (
                        <div
                            key={i}
                            style={{
                                flex: 1,
                                background: activeBars > i ? "green" : "gray",
                                transition: "background 0.2s",
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PitchDetector;
