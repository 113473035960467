import { createSlice } from "@reduxjs/toolkit";
import joinCallActions, {
  getVideoStream,
  toggleVideoTracks,
  toggleAudioTracks,
} from "./actions";

const initialState = {
  uiState: {
    streamLoading: true,
    streamError: null,
    cameraOn: false,
    audioOn: false,
    waitingCamera: false,
    audioRequiredMessage: false,
    cameraRequiredMessage: false,
    approvalMessage: "",
    approvalWaiting: false,
  },
};

export const joinCallSlice = createSlice({
  name: "joinCallSlice",
  initialState,
  reducers: joinCallActions,
  extraReducers: (builder) => {
    builder
      .addCase(getVideoStream.pending, (state) => {
        state.uiState.streamLoading = true;
      })
      .addCase(getVideoStream.rejected, (state, payload) => {
        state.uiState.streamLoading = false;
        state.uiState.streamError = payload.error.message;
      })
      .addCase(getVideoStream.fulfilled, (state, { payload: { isAudio, isCamera } }) => {
        state.uiState.streamLoading = false;
        state.uiState.audioOn = isAudio;
        state.uiState.cameraOn = isCamera;
        state.uiState.cameraRequiredMessage = !isCamera;
        state.uiState.audioRequiredMessage = !isAudio;
      })
      .addCase(toggleVideoTracks.pending, (state) => {
        state.uiState.waitingCamera = true;
      })
      .addCase(toggleVideoTracks.rejected, (state) => {})
      .addCase(toggleVideoTracks.fulfilled, (state, { payload: { cameraOn } }) => {
        state.uiState.cameraOn = cameraOn;
        state.uiState.waitingCamera = false;
      })
      .addCase(toggleAudioTracks.pending, (state) => {})
      .addCase(toggleAudioTracks.rejected, (state) => {})
      .addCase(toggleAudioTracks.fulfilled, (state, { payload: { audioOn } }) => {
        state.uiState.audioOn = audioOn;
      });
  },
});

export default joinCallSlice.reducer;
