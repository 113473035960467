import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calculateTimeDifferenceInSeconds } from './CalculateTimeDiference';
import { AddCallingTranscribe } from '../../data/api/Api';
import { useLocation } from 'react-router-dom';
import { generalSlice } from '../SharedSlices/generalslice/generalSlice';
import { message } from 'antd';

const SpeechToText = ({ SocketConnection }) => {

    const location = useLocation();
    const dispatch = useDispatch()

    const isTranscribe = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.isTranscribe;
    });

    const { userId, email, userName, roomId, joinDateTime, speechRecognitionFlag } = useSelector(
        ({ generalSlice }) => generalSlice
    );
    const audioOn = useSelector(
        ({ roomSlice: { users }, generalSlice }) =>
            users[generalSlice?.userId]?.uiState?.audioOn
    );
    const recognitionRef = useRef(null);
    const isRecognitionStartedRef = useRef(false);

    useEffect(() => {
        if (!speechRecognitionFlag) {
            message.info("Speech Recognition is not availaible in this browser.")
        }
    }, [speechRecognitionFlag])
    useEffect(() => {
        // Check if SpeechRecognition is supported
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    
        if (!SpeechRecognition) {
            console.log("Speech recognition is not supported in this browser.");
            dispatch(generalSlice.actions.updateSpeechRecognitionFlag(false));
            return;
        }
    
        // Initialize speech recognition
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.interimResults = true;
    
        if (audioOn && isTranscribe) {
            recognitionRef.current.addEventListener("result", (e) => {
                const text = Array.from(e.results)
                    .map((result) => result[0].transcript)
                    .join("");
    
                if (e.results[0].isFinal) {
                    const currentTime = new Date().getTime();
                    const data = {
                        roomId,
                        memberId: userId,
                        memberEmail: email,
                        memberName: userName,
                        message: text,
                        timingMeeting: calculateTimeDifferenceInSeconds(currentTime, joinDateTime),
                        dateTime: new Date(currentTime).toISOString(),
                    };
    
                    if (isTranscribe) {
                        AddCallingTranscribe([data]);
                        SocketConnection.Events({ type: "SEND_TRANSCRIBE", payload: data });
                    }
                }
            });
    
            recognitionRef.current.onerror = function (event) {
                if (event.error === "no-speech") {
                    console.log("No speech detected. Please try again.");
                    restartRecognition();
                } else {
                    console.error("Speech recognition error:", event.error);
                    restartRecognition();
                }
            };
    
            recognitionRef.current.addEventListener("end", () => {
                restartRecognition();
            });
    
            startRecognition();
        }
    
        // Cleanup on unmount
        return () => {
            stopRecognition();
        };
    }, [isTranscribe, audioOn]);

    const startRecognition = () => {
        if (!isRecognitionStartedRef.current) {
            recognitionRef.current.start();
            isRecognitionStartedRef.current = true;
        }
    };
    const stopRecognition = () => {
        // if (isRecognitionStartedRef.current) {
        recognitionRef.current.stop();
        // }
        isRecognitionStartedRef.current = false;
    };

    const restartRecognition = () => {
        stopRecognition();
        startRecognition();
    };

    return <></>;
};

export default SpeechToText;
