import { createSlice } from "@reduxjs/toolkit";
import { windowType } from "../../../app/utils/enums";
import roomActions, { toggleVideoTracks, toggleAudioTracks, toggleCamera, updateApprovalMembers } from "./actions";

const initialState = {
  users: {},
  uiState: {
    selectedStatus: null,
    isHandRised: false,
    showEndMeetingModal: false,
    showAddParticipantsModal: false,
    viewType: windowType.Desktop,
    pinCount: 0,
    alert: false,
    waitingCamera: false,
    alertMessage: "",
    Loading: true,
    isScreenShare: false,
    isMileBoardShare: false,
    screenShareAllow: true,
    chatList: [],
    isChatBarClosed: true,
    tempNotificationLength: 0,
    isLock: false,
    meetingApprovalSwitch: true,
    currentVideoDeviceIndex: 0,
    currentVideoDevice: null,
    videoDevices: [],
    currentInputDevice: 0,
    currentOutputDevice: 0,
    outputDevices: [],
    isTranscribe: false,
    transcribeList: [],
    notificationMessage: {},
    notificationApproval: 0,
    approvalMembers: [],
    timer: false,
  },
  userIds: [],
};

export const roomSlice = createSlice({
  name: "roomSlice",
  initialState,
  reducers: roomActions,
  extraReducers: (builder) => {
    builder
      .addCase(toggleVideoTracks.pending, (state) => {
        state.uiState.waitingCamera = true;
      })
      .addCase(toggleVideoTracks.rejected, (state) => {})
      .addCase(toggleVideoTracks.fulfilled, (state, { payload: { cameraOn } }) => {
        const firstUserKey = Object.keys(state.users)[0];
        if (firstUserKey) {
          state.users[firstUserKey].uiState.cameraOn = cameraOn;
        }
        state.uiState.waitingCamera = false;
      })
      .addCase(toggleCamera.pending, (state) => {
        state.uiState.waitingCamera = true;
      })
      .addCase(toggleCamera.rejected, (state) => {})
      .addCase(toggleCamera.fulfilled, (state, { payload: { cameraOn, newIndex } }) => {
        const firstUserKey = Object.keys(state.users)[0];
        if (firstUserKey) {
          state.users[firstUserKey].uiState.cameraOn = cameraOn;
        }
        state.uiState.waitingCamera = false;
        state.uiState.currentVideoDeviceIndex = newIndex;
      })
      .addCase(toggleAudioTracks.pending, (state) => {})
      .addCase(toggleAudioTracks.rejected, (state) => {})
      .addCase(toggleAudioTracks.fulfilled, (state, { payload: { audioOn } }) => {
        const firstUserKey = Object.keys(state.users)[0];
        if (firstUserKey) {
          state.users[firstUserKey].uiState.audioOn = audioOn;
        }
      })
      .addCase(updateApprovalMembers.pending, (state) => {})
      .addCase(updateApprovalMembers.rejected, (state) => {})
      .addCase(updateApprovalMembers.fulfilled, (state, { payload }) => {
        state.uiState.approvalMembers = payload;
      });
  },
});

export default roomSlice.reducer;
