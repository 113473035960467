import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateAudioTrack } from "../../../domain/actions/ToggleAudioTrack";
import { updateVideoTrack, updateCamera } from "../../../domain/actions/ToggleVideoTrack";
import produce from "immer";
import { getApprovalMembers } from "../../../data/api/Api";
//ui related reducers
function setView(state, { payload }) {
  state.uiState.viewType = payload;
}
function setPin(state, { payload: { userId, isPin } }) {
  state.users[userId].uiState.pin = isPin;
}
function updatePinCount(state, { payload: { isPin } }) {
  isPin ? (state.uiState.pinCount += 1) : (state.uiState.pinCount -= 1);
}
function setAlert(state, { payload: { alert } }) {
  state.uiState.alert = alert;
}
function setAlertMessage(state, { payload: { message } }) {
  state.uiState.alertMessage = message;
}
function updateSidebarUsers(state, { payload: { updatedLayoutUsers, myId } }) {
  updatedLayoutUsers.map((user) => {
    state.users[user].uiState.pin = false;
  });
}
function updateMainScreenUsers(
  state,
  { payload: { updatedMainScreenUsers, myId } }
) {
  updatedMainScreenUsers.map((user) => {
    state.users[user].uiState.pin = true;
  });
}
function updatePinCountForLayout(state, { payload: { updatedMainScreenUsers } }) {
  state.uiState.pinCount = updatedMainScreenUsers.length;
}

function updateRoomLock(state, { payload }) {
  state.uiState.isLock = payload.isLock
}

function updateMeetingApprovalSwitch(state, { payload }) {
  state.uiState.meetingApprovalSwitch = payload
}
function updateNotificationMessage(state, { payload }) {
  state.uiState.notificationMessage = payload
}
//Webrtc reducers
export const toggleAudioTracks = createAsyncThunk(
  "roomSlice/toggleAudioTracks",
  async (data, { getState }) => {
    const state = getState();
    return await updateAudioTrack(state.roomSlice.users[state.generalSlice.userId], data);
  }
);

export const toggleVideoTracks = createAsyncThunk(
  "roomSlice/toggleVideoTracks",
  async (data, { getState }) => {
    const state = getState();
    return await updateVideoTrack(state.roomSlice.users[state.generalSlice.userId], data);
  }
);
export const toggleCamera = createAsyncThunk(
  "roomSlice/toggleCamera",
  async (data) => {
    return await updateCamera(data);
  }
);

// users reducers
function addUserVideo(state, { payload: { userId, video } }) {
  state.users[userId].uiState.cameraOn = video;
}
function addUserAudio(state, { payload: { userId, audio } }) {
  state.users[userId].uiState.audioOn = audio;
}
function addUserData(state, { payload: { userId, email, socketId, userName } }) {
  state.users = {
    ...state.users,
    [userId]: {
      uiState: {
        cameraOn: false,
        audioOn: false,
        pin: false,
      },
      socketId,
      email,
      userName
    },
  }
  state.userIds = [...state.userIds, userId];
}
// function addUser(
//   state,
//   { payload: { email, userId, socketId, video, audio } }
// ) {
//   state.users = {
//     ...state.users,
//     [userId]: {
//       uiState: {
//         cameraOn: video,
//         audioOn: audio,
//         // screenShare: false,
//         pin: false,
//       },
//       socketId,
//       email,
//     },
//   }
//   state.userIds=[...state.userIds , userId]
//   ;
// } 
function startScreenShare(state, { payload: { userId } }) {
  state.users[userId + "_screen_share"].uiState.pin = true;
  state.uiState.screenShareAllow = false;
  state.uiState.pinCount += 1;
}
function addScreenShare(state, { payload: { userId } }) {
  state.users = {
    ...state.users,
    [userId + "_screen_share"]: {
      uiState: {
        cameraOn: true,
        pin: false,
      },
    },
  }
  state.userIds = [...state.userIds, userId + "_screen_share"]
  state.uiState.isScreenShare = true;

}
function stopScreenShare(state, _) {
  state.uiState.screenShareAllow = true;
  state.uiState.isScreenShare = false;
}
function deleteUserUi(state, { payload: { userId } }) {
  const newState = produce(state, (draftState) => {
    delete draftState.users[userId];

  })
  state.users = newState.users;
}
function deleteUser(state, { payload: { userId } }) {
  const newState = produce(state, (draftState) => {

    draftState.userIds = draftState.userIds.filter((value) => value !== userId);
  })
  state.userIds = newState.userIds;
  let count = 0;
  state.userIds.map((userId) => {
    if (state.users[userId].uiState.pin) {
      count += 1;
    }
  })
  state.uiState.pinCount = count;
}
function callStarted(state, _) {
  state.uiState.Loading = false;
}
function LeaveCall(state, { payload }) {
  return {
    users: {},
    uiState: {
      ...state.uiState,
      pinCount: 0,
      alert: false,
      alertMessage: "",
      isScreenShare: false,
      screenShareAllow: true,
      Loading: true,
      isTranscribe: false,
    },
    userIds: []
  };
}
function cameraOff(state, { payload: { userId } }) {
  state.users[userId].uiState.cameraOn = false;
}
function cameraOn(state, { payload: { userId } }) {
  state.users[userId].uiState.cameraOn = true;
}
function audioOff(state, { payload: { userId } }) {
  state.users[userId].uiState.audioOn = false;
}
function audioOn(state, { payload: { userId } }) {
  state.users[userId].uiState.audioOn = true;
}
function updateChat(state, { payload }) {
  state.uiState.chatList = [...state.uiState.chatList, payload];
}
function updateTranscribe(state, { payload }) {
  state.uiState.transcribeList = [...state.uiState.transcribeList, payload];
}
function resetNotificationLength(state, _) {
  // console.log("reset done");
  state.uiState.notificationLength = 0;
  // state.uiState.tempNotificationLength=state.uiState.chatList.length;
}
function updateTempNotificationLength(state, _) {
  state.uiState.tempNotificationLength = state.uiState.chatList.length;
  // console.log("update tempNotificationLength",state.uiState.tempNotificationLength)
}
function setNotificationLength(state, _) {
  state.uiState.notificationLength = state.uiState.chatList.length - state.uiState.tempNotificationLength;
  // console.log("set notificationLength",state.uiState.notificationLength);
}
function updateChatViewed(state, { payload }) {
  // console.log(payload);
  state.uiState.isChatBarClosed = payload;
}
function updateIsTranscribe(state, { payload }) {
  // console.log(payload);
  state.uiState.isTranscribe = payload;
}
function updateModalView(state, { payload }) {
  // console.log(payload);
  state.uiState.showEndMeetingModal = payload;
}
function updateIsHandRised(state, { payload }) {
  // console.log(payload);
  state.uiState.isHandRised = payload;
}
function updateAddParticipantModalView(state, { payload }) {
  // console.log(payload);
  state.uiState.showAddParticipantsModal = payload;
}
function updateSelectedStatus(state, { payload }) {
  state.uiState.selectedStatus = payload;
}
function updateMileBoardShare(state, { payload }) {
  state.uiState.isMileBoardShare = payload
}
function updateCurrentVideoDeviceIndex(state, { payload }) {
  state.uiState.currentVideoDeviceIndex = payload
}
function updateVideoDevices(state, { payload }) {
  state.uiState.videoDevices = payload
}
function updateCurrentOutputDevice(state, { payload }) {
  state.uiState.currentOutputDevice = payload
}
function updateCurrentInputDevice(state, { payload }) {
  state.uiState.currentInputDevice = payload
}
function updateOutputDevices(state, { payload }) {
  state.uiState.outputDevices = payload
}
function updateCurrentVideoDevice(state, { payload }) {
  state.uiState.currentVideoDevice = payload
}
function updateNotificationApproval(state, { payload }) {
  state.uiState.notificationApproval += 1
}
function updateTimer(state, { payload }) {
  state.uiState.timer = payload;
}
// function updateApprovalMembers(state, { payload }) {
//   state.uiState.approvalMembers = payload
// }

export const updateApprovalMembers = createAsyncThunk(
  "roomSlice/updateApprovalMembers",
  async (roomId) => {
    const approvalMembers = await getApprovalMembers(roomId)
    return approvalMembers;

  }
);

const roomActions = {
  updateTimer,
  updateSelectedStatus,
  updateMeetingApprovalSwitch,
  updateNotificationApproval,
  updateNotificationMessage,
  updateIsTranscribe,
  updateOutputDevices,
  updateCurrentVideoDevice,
  updateCurrentInputDevice,
  updateCurrentOutputDevice,
  updateIsHandRised,
  updateAddParticipantModalView,
  updateRoomLock,
  updateModalView,
  updateMileBoardShare,
  setNotificationLength,
  resetNotificationLength,
  updateTempNotificationLength,
  updateChatViewed,
  updateChat,
  updateTranscribe,
  LeaveCall,
  audioOn,
  audioOff,
  cameraOn,
  cameraOff,
  callStarted,
  addUserVideo,
  addUserAudio,
  addUserData,
  updatePinCountForLayout,
  updateMainScreenUsers,
  setAlertMessage,
  setAlert,
  updatePinCount,
  setView,
  setPin,
  updateSidebarUsers,
  deleteUser,
  addScreenShare,
  stopScreenShare,
  startScreenShare,
  deleteUserUi,
  updateCurrentVideoDeviceIndex,
  updateVideoDevices
};

export default roomActions;
